<template>
    <div class="detail">
        <div class="goods">
            <div class="home">
                <span @click="Allusedcars">全部二手车</span><i class="el-icon-arrow-right" style="margin-top: 3px;"></i>
                <span style="color: #999999;">二手车详情</span>
            </div>
        </div>
        <div class="detail_top">
            <div class="detail_top_left">
                <div>
                    <div class="ee">
                        <div class="cc">
                            <img src="../../assets/tupian/WechatIMG1042.png" alt="">
                        </div>
                        <div class="ww">
                            <img @click="change('../../assets/tupian/WechatIMG1043.png')" src="../../assets/tupian/WechatIMG1043.png" alt="">
                            <img @click="change('../../assets/tupian/WechatIMG1044.png')" src="../../assets/tupian/WechatIMG1044.png" alt="">
                            <img @click="change('../../assets/tupian/WechatIMG1046.png')" src="../../assets/tupian/WechatIMG1046.png" alt="">
                            <img @click="change('../../assets/tupian/WechatIMG1047.png')" src="../../assets/tupian/WechatIMG1047.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="detail_top_right">
                <span class="items">Jeep 自由侠 2018款 互联大屏版 1.80T 自动高能版</span>
                <el-descriptions :column="4" direction="vertical" :colon="false">
                    <el-descriptions-item label="上牌时间">2018-5-1</el-descriptions-item>
                    <el-descriptions-item label="表显里程">2.56万公里</el-descriptions-item>
                    <el-descriptions-item label="排量">1.80T</el-descriptions-item>
                    <el-descriptions-item label="排放标准">国五（国V）</el-descriptions-item>
                </el-descriptions>
                <span class="num">9.00万元</span>
                <el-row>
                    <el-col style="text-align:right;">
                        <span>车辆编号:198765421</span>
                    </el-col>
                </el-row>
                <el-row :gutter="24" class="title">
                    <el-col :span="8">
                        <span>首付</span> <span style="color: rgb(244,62, 38);">2.17</span><span>万起</span>
                    </el-col>
                    <el-col :span="8">
                        <span style="color: rgb(34,119, 253);">129</span><span>次浏览</span>
                    </el-col>
                    <el-col :span="8">
                        <span style="color: rgb(34,119, 253);">9</span><span>人已收藏</span>
                    </el-col>
                </el-row>
            </div>
        </div>
        <div class="Basic">
            <span class="items">车辆基本信息</span>
            <el-descriptions :column="3" :colon="false">
                <el-descriptions-item v-for="(key, value) in dataList" :key="key" :label="value">
                    {{key}}
                </el-descriptions-item>
            </el-descriptions>
            <div class="button">
                <el-button type="primary" plain @click="configuration">查看参数配置</el-button>
            </div>
        </div>
        <div class="miaoshu">
            <span class="items">车辆描述</span>
            <el-col> 四条轮胎是倍耐力的，车主卖车钱前换的，空调制冷一般，购回的时候需要收拾，全车车架架构无事故，原厂车漆，经检测无钣金修复或更换面板部件。</el-col>
        </div>
        <div class="Vehicle">
            <span class="items">车辆照片</span>
            <div class="aa">
                <div class="ee">
                    <div class="dd">
                        <div class="cc">
                            <img @click="change('../../assets/tupian/WechatIMG1042.png')" src="../../assets/tupian/WechatIMG1042.png" alt="">
                            <img @click="change('../../assets/tupian/WechatIMG1042.png')" src="../../assets/tupian/WechatIMG1042.png" alt="">
                        </div>
                        <div class="cc">
                            <img @click="change('../../assets/tupian/WechatIMG1042.png')" src="../../assets/tupian/WechatIMG1042.png" alt="">
                            <img @click="change('../../assets/tupian/WechatIMG1042.png')" src="../../assets/tupian/WechatIMG1042.png" alt="">
                        </div>
                    </div>
                    <div class="samll">
                        <img @click="change('../../assets/tupian/WechatIMG1043.png')" src="../../assets/tupian/WechatIMG1043.png" alt="">
                        <img @click="change('../../assets/tupian/WechatIMG1044.png')" src="../../assets/tupian/WechatIMG1044.png" alt="">
                        <img @click="change('../../assets/tupian/WechatIMG1046.png')" src="../../assets/tupian/WechatIMG1046.png" alt="">
                        <img @click="change('../../assets/tupian/WechatIMG1047.png')" src="../../assets/tupian/WechatIMG1047.png" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="tuijian">
            <span class="items">更多推荐</span>
            <div class="box" @click="details">
                <ul style="padding:-40px">
                    <li v-for="(v,index) in json.list" :key="index">
                        <div class="Single">
                            <img v-bind:src="v.src" alt="">
                            <h1 class="car">{{v.car}}</h1>
                            <h4 class="time">{{v.des}}</h4>
                            <p class="num1">{{v.price}}</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>

</template>


<script>
export default {
    data() {
        return {
            json: {
                list: [
                    {
                        src: require("../../assets/tupian/WechatIMG927.png"),
                        car: "起亚K3 2013款 1.6L 自动DLX",
                        des: "2013年 | 8.50万公里",
                        price: "5.29万",
                    },
                    {
                        src: require("../../assets/tupian/WechatIMG942.png"),
                        car: "马自达3星聘 2011款 1.6L 手动舒适型",
                        des: "2011年 | 10.00万公里",
                        price: "3.00万",
                    },
                    {
                        src: require("../../assets/tupian/WechatIMG953.png"),
                        car: "Jeep 指南者 2015款 2.0L两驱豪华版",
                        des: "2015年 | 9.20万公里",
                        price: "9.28万",
                    },
                    {
                        src: require("../../assets/tupian/WechatIMG966.png"),
                        car: "沃尔沃S60 2011款 2.0T 舒适版",
                        des: "2011年 | 13.12万公里",
                        price: "6.12万",
                    },
                ],
            },
            dataList: {
                上牌时间: "2020-01",
                车辆所在地: "宁波",
                使用性质: "非运营",
                表显里程: "1.4万公里",
                变速箱: "自动",
                销售数量: "2把",
                排放标准: "国VI",
                排量: "2.0L",
                年检到期: "2022-03",
                过户次数: "0次",
                车身颜色: "白色",
                交强险到期: "2022-02",
            },
            handoff: require("../../assets/tupian/WechatIMG927.png"),
        };
    },
    methods: {
        configuration() {
            this.$router.push("/cardetails/configuration");
        },
        change: function (url) {
            this.handoff = url;
        },
        //点击详情挑转
        details() {
            this.$router.push("/cardetails");
        },
        Allusedcars() {
            this.$router.push("/Allusedcars");
        },
    },
};
</script>

<style lang="less" scoped>
.detail {
    width: 90%;
    height: 100%;
    min-width: 1200px;
    margin: 0 auto;
}
.detail_top {
    width: 100%;
    display: flex;
    height: 520px;
    margin: 0 auto;
    .detail_top_left {
        width: 45%;
        .ee {
            height: 500px;
            // margin: 0 auto;
            .cc {
                width: 550px;
                display: flex;
                margin: 0 auto;
            }
            .cc img {
                width: 550px;
                height: 350px;
                margin: 20px;
            }
            .ww {
                height: 100px;
                width: 500px;
                display: flex;
                margin: 0 auto;
                // justify-content: space-between;
            }
            .ww img {
                height: 104px;
                width: 100px;
                margin: 0 20px;
            }
        }
    }
    .detail_top_right {
        width: 45%;
        .items {
            height: 80px;
            line-height: 80px;
            font-size: 28px;
        }
        .el-descriptions {
            margin: 30px 0;
            height: 50px;
        }
    }
}
.Basic {
    width: 100%;
    margin: 0 auto;
    height: 250px;
    .button {
        width: 10%;
        margin: 0 auto;
    }
}
.items {
    height: 50px;
    line-height: 50px;
    font-size: 28px;
    margin: 25px 0px;
}
.miaoshu {
    width: 100%;
    margin: 0 auto;
}
.page-item {
    height: 240px;
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    box-shadow: 1px 4px 5px 2px rgb(232, 232, 232);
}
.time {
    font-size: 13px;
    color: #999;
}
.bottom {
    margin-top: 13px;
    line-height: 12px;
}
// .image {
//     width: 50%;
//     display: block;
// }

.title {
    height: 70px;
    line-height: 70px;
    font-size: 14px;
    font-weight: 600;
}
.el-col {
    margin: 25px 0px;
}
.Vehicle {
    width: 100%;
    // margin: 0 auto;
    height: 1100px;

}
.aa {
    width: 90%;
    margin: 0 auto;
}
.ee {
    height: 500px;
    width: 50%;
    // margin: 0 auto;
}
.cc {
    width: 480px;
    display: flex;
    margin: 0 auto;
}
.cc img {
    width: 550px;
    height: 350px;
    margin: 20px;
}

.samll {
    width: 1200px;
    height: 200px;
    margin: 0 auto;
    // padding: 0px 25px;
}
.samll img {
    height: 168px;
    width: 250px;
    margin-left: 10px;
}

.page-item1 {
    width: 80%;
    margin: 0 auto;
}
.bottom {
    margin-top: 13px;
    line-height: 12px;
}
.item {
    height: 70px;
    line-height: 70px;
    font-size: 24px;
    margin: 15px;
}
.item-em {
    height: 70px;
    line-height: 70px;
    font-size: 14px;
    margin: 0 15px 15px 0;
}
.tuijian {
    width: 100%;
    .box {
        width: 100%;
        margin: 0 auto;
    }
    .box ul {
        display: flex;
        // flex-wrap: wrap;
    }
    .box li {
        padding: 3px;
        box-shadow: 1px 4px 5px 2px rgb(232, 232, 232);
        list-style: none;
        margin: 10px;
        // border: 1px solid #eee;
    }
    .box img {
        width: 100%;
        height: 168px;
    }
}
.Single {
    display: inline-block;
    width: 250px;
    height: 285px;
    margin: 15px 0px;
    cursor: pointer;
}

.car {
    word-wrap: break-word;
    word-break: break-all;
    font-size: 16px;
    white-space: nowrap; /*强制span不换行*/
    display: inline-block; /*将span当做块级元素对待*/
    width: 100%; /*限制宽度 可设px*/
    overflow: hidden; /*超出宽度部分隐藏*/
    text-overflow: ellipsis; /*超出部分以点号代替*/
}
.num1 {
    font-size: 18px;
    color: rgb(255, 0, 0);
}
.time {
    font-size: 13px;
    color: #999;
}
.num {
    font-size: 44px;
    color: rgb(255, 0, 0);
    height: 62px;
    line-height: 62px;
}
.home {
    width: 20%;
    margin: 20px 20px;
    cursor: pointer;
}
</style>